import Link from 'next/link'

export const Custom404 = ({ onTryAgain, title = 'Page Not Found' }: { title?: string; onTryAgain?: () => void }) => {
  return (
    <>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 lg:-translate-y-1/4 min-w-[250px]">
        <h1 className="text-4xl md:text-6xl font-semibold leading-tight text-white text-center">{title}</h1>
        <div className="flex flex-col gap-2 sm:flex-row justify-center items-center mt-[40px]">
          {onTryAgain && (
            <button
              className="block text-base rounded-lg py-2 px-10 bg-white text-neutral-950 font-bold text-center w-fit"
              onClick={() => onTryAgain()}
            >
              Try Again
            </button>
          )}
          <Link
            href="/"
            className="block text-base rounded-lg py-2 px-10 bg-white hover:bg-neutral-200 transition-colors duration-200 text-neutral-950 font-bold text-center w-fit"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </>
  )
}
