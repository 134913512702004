'use client'

import { Custom404 } from '@ethena/shared-ui/src/screens/Custom404'
import { Bai_Jamjuree } from 'next/font/google'
import Head from 'next/head'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import '@ethena/shared-styles/common.css'
import '../styles/globals.css'

const baiJamjuree = Bai_Jamjuree({ subsets: ['latin'], weight: ['200', '300', '400', '500', '600', '700'] })

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html lang="en">
      <Head>
        <meta name="robots" content="none" key="none" />
      </Head>
      <body>
        <main className={baiJamjuree.className}>
          <Custom404 title="Something went wrong!" onTryAgain={() => reset()} />
        </main>
      </body>
    </html>
  )
}
